import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";

export function getHeatmapDeclChunk() {
	return wgsl/*wgsl*/`
	fn lmap(value: f32, inMin: f32, inMax: f32, outMin: f32, outMax: f32) -> f32 {
		return clamp(outMin + ((outMax - outMin) * (value - inMin)) / (inMax - inMin), outMin, outMax);
	}

	// Gradient with multiple colors in non-equal intervals
	fn colormap(x: f32) -> vec3f {
		var colorsAndStops = ibl.colorsAndStops;
		for (var i = 1; i < NUM_STOPS; i++) {
			if (x <= colorsAndStops[i].stop) {
				var stopPct = lmap(x, colorsAndStops[i - 1].stop, colorsAndStops[i].stop, 0.0, 1.0);
				return mix(colorsAndStops[i - 1].color, colorsAndStops[i].color, stopPct);
			}
		}

		// In case 'x' goes beyond upper bound.
		return colorsAndStops[NUM_STOPS - 1].color;
	}
	`;
};

export function getHeatmapChunk() {
	return wgsl/*wgsl*/`

	var sensorCount = materialUniforms.heatmapSensorCountAndOffset & 0x000000ff;
	if (sensorCount > 0) {

		var sensorOffset = materialUniforms.heatmapSensorCountAndOffset >> 8;
		var heatResult: f32 = 0.0;
		var heatSum: f32 = 0.0;
		var heatWsum: f32 = 0.000001;
		var heatFound: bool = false; // at exact location of the sensor
		var heatmapAlpha = ibl.heatmapAlpha;

		for (var i = sensorOffset; i < sensorOffset + sensorCount; i++) {
			var mydist = distance(in.vWorldPosition, heatmapSensors[i].xyz);
			if (mydist < 0.00001) {
				// So the exact pixel of the sensor will get the right color, but pretty much nothing beside it.
				heatResult = heatmapSensors[i][3];
				heatFound = true;
			} else {
				var w = (1.0 / (mydist * mydist));
				heatSum += (heatmapSensors[i][3] * w);
				heatWsum += w;
			}
		}

		if (!heatFound) {
			heatResult = heatSum / heatWsum;
		}

		// Defaults without influence from any sensor
		var sensorColor = vec3f(0.5);
		if (heatResult >= 0.0) {
			heatResult = clamp(heatResult, 0.0, 1.0);
			sensorColor = colormap(heatResult);
		}

		// If this chunk is used in shaders that render ghosted objects, we need to check for that and don't apply the
		// heatmap color, i.e. use 'ghosted ? 0.0 : heatmapAlpha' below.
		output.color = vec4f(mix(vec3f(luminance_post(output.color.rgb)), sensorColor, heatmapAlpha), output.color.a);
		if (heatmapAlpha > 0.0) {
			output.color[3] = max(output.color.a, heatmapAlpha);
		}
	}
	`;
};
