import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";


export function getEnvMapSampleChunk(isGamma, isRgbm, gammaInput) {

return wgsl/*wgsl*/`

// Precomputed sin/cos of the environment rotation
//DT uniform float envRotationSin;
//DT uniform float envRotationCos;
const envRotationSin = 0.0;
const envRotationCos = 1.0;

fn adjustLookupVector(lookup: vec3f) -> vec3f {
    return vec3f(
            envRotationCos * lookup.x - envRotationSin * lookup.z,
            lookup.y,
            envRotationSin * lookup.x + envRotationCos * lookup.z);
}

//NOTE: This depends on the specific encoding used.
//We use the environment preset's built in exposure correction,
//a gamma of 2.0 and an extra factor of 16
//when generating the cube map in the modified CubeMapGen tool
//See this article by Karis for details: http://graphicrants.blogspot.ca/2009/04/rgbm-color-encoding.html
fn RGBMDecode(vRGBM: vec4f, exposure: f32) -> vec3f {
    var ret = vRGBM.rgb * (vRGBM.a * 16.0); //variable factor in alpha channel + fixed factor of 16.0
    ret *= ret; //remove gamma of 2.0 to go into linear space
    ret *= exposure; //apply exposure to get back original intensity
    return ret;
}

//Gamma encoded half-float or float input texture. See DecodeEnvMap for details.
fn GammaDecode(vRGBA: vec4f, exposure: f32) -> vec3f {
    return vRGBA.xyz * vRGBA.xyz * exposure;
}

fn sampleIrradianceMap(dirWorld: vec3f, irrMap: texture_cube<f32>, s: sampler, exposure: f32) -> vec3f {

    var cubeColor4 = textureSample(irrMap, s, adjustLookupVector(dirWorld));

#if ${isGamma}
    var indirectDiffuse = GammaDecode(cubeColor4, exposure);
    return indirectDiffuse;
#elif ${isRgbm}
    var indirectDiffuse = RGBMDecode(cubeColor4, exposure);
    return indirectDiffuse;
#else
    var indirectDiffuse = cubeColor4.xyz;

	#if ${gammaInput}
		indirectDiffuse.xyz *= indirectDiffuse.xyz;
	#endif

    return indirectDiffuse;

#endif
}

fn sampleReflection(dir: vec3f, envMap: texture_cube<f32>, s: sampler, mipIndex: f32, exposure: f32) -> vec3f {

    var adjDir = adjustLookupVector(dir);

#if ${isGamma}
    //TODO: is it textureSampleBias or textureSampleLevel? Used to be textureCubeLod in WebGL
    var envTexColor = textureSampleLevel( envMap, s, adjDir, mipIndex );
    return GammaDecode(envTexColor, exposure);
#elif ${isRgbm}
    var envTexColor = textureSampleLevel( envMap, s, adjDir, mipIndex );
    return RGBMDecode(envTexColor, exposure);
#else

    //Assumes this code path is non-HDR and non-blurred reflection map, like vanilla three.js

    var envTexColor = textureSample( envMap, s, adjDir );
    var cubeColor = envTexColor.xyz;

	#if ${gammaInput}
		cubeColor *= cubeColor;
	#endif

	return cubeColor;
#endif

}
`;
}
