import { ConsolidationBuilder } from "./Consolidation";
import { InstanceBufferBuilder } from "./InstanceBufferBuilder";
import { MATERIAL_VARIANT } from "../../render/MaterialManager";
import * as THREE from "three";
import { NodeArray } from "../BVHBuilder";

/**
 * This file contains code to create a Consolidation (see Consolidation.js) from all fraqments of a FragmentList.
 * Rendering the consolidation instead of the individual fragments can improve rendering performance
 * for models containing a large number of small objects.
 */

/**
 * Function to check if the consolidation job has been canceled. Throws if so.
 *
 * @callback fnStopIfCanceled
 * @throws {Error} If the consolidation job has been canceled
 */

/**
 * Context information for the consolidation job
 * 
 * @typedef {Object} ConsolidationJobContext
 * @property {fnStopIfCanceled} stopIfCanceled            - Checks if the consolidation job has been canceled. Throws if so.
 * @property {ConsolidationMap|null} consolidationMap     - If available, the intermediate results can be reused from a previous
 *                                                          consolidation to accelerate preprocessing. Note that a ConsolidationMap
 *                                                          can only be reused if the FragmentList and consolidation parameters are exactly the same.
 * @property {number} byteLimit                           - The memory limit for consolidation
 * @property {boolean} forceConsolidationMapRecomputation - If true, the consolidation map will be recomputed
 * @property {Worker} worker                              - The worker that is used for consolidation
 * @property {number} id                                  - Consolidation Job identifier
 * @property {number} byteLimit                           - Merging geometries is the most efficient technique in terms
 *                                                          of rendering performance. But, it can strongly increase
 *                                                          the memory consumption, particularly because merged
 *                                                          geometry cannot be shared, i.e. multiple instances of
 *                                                          a single geometry must be replicated per instance for merging.
 *                                                          Therefore, the memory spent for merging is restricted.
 *                                                          A higher value may make rendering faster, but increases (also GPU) memory
 *                                                          workload.
 * @property {Object} bvhOptions                          - BVH computation options
 * @property {Object} consolidationBVH                    - The BVH computed during consolidation
 * @property {NodeArray} consolidationBVH.nodes           - The BVH nodes
 * @property {Int32Array} consolidationBVH.primitives     - The BVH primitives
 * @property {number} syncTimeSlice                       - Max miliseconds to spend on consolidation per iteration
 * @property {boolean} useDeferredConsolidation           - If true, consolidation will only compute the initial data. Consolidated buffers will be computed on demand.
 */

export const CONSOLIDATION_STOP_MARKER = Object.freeze({
    MAP_CREATION : 'Creating Consolidation Map',
    INSTANCING: 'Applying Instancing to Fragments',
    SORTING: 'Sorting Fragments by Consolidation Costs',
    BVH: 'Computing Consolidation BVH'
});

const MINIMAL_INSTANCES_FOR_INSTANCED_RENDERING = 1; // This is a parameter we will want to tune
const MAX_INDICES_PER_RANGE = 50_000;
const FRAGMENTS_PER_TIME_CHECK = 1_000;

let workerJobId = 0;

/**
 *  Creates a consolidated representation for a given list of fragment ids. Consolidation is only done for the
 *  first n elements of the fragIds array, where n is chosen in a way that we stop if a given memory cost limit is reached.
 *
 *  Consolidation is done here by merging fragment Geometries into larger vertex buffers. If multiple fragments share
 *  the same geometry, the geometry is replicated. Therefore, this step is only used for the smaller fragments
 *  with not too many instances.
 *
 *   @param {FragmentList}            fragList         - Fragmentlist of the model to consolidate
 *   @param {Int32Array[]}            fragIds          - Array of fragment ids to consolidate
 *   @param {Uint32Array|null}        fragIdToNodeIdx  - Optional: If available, the bvh node index for each fragment
 *   @param {ConsolidationJobContext} jobContext       - Context information for the consolidation job
 *
 *   @returns {Promise<Object>} Result object containing...
 *                      result.consolidation: Instance of Consolidation
 *                      result.fragIdCount:   Defines a range within fragIds:
 *                                            Only fragIds[0], ... , fragIds[result.fragIdCount-1] were consolidated.
 */
async function createConsolidationMap(fragList, fragIds, fragIdToNodeIdx, jobContext) {

    return new Promise((resolve) => {
        // reused in loop below
        var fragBox = new THREE.Box3();

        var mc = new ConsolidationBuilder();
        var i = 0;

        const addMoreGeoms = () => {
            jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.MAP_CREATION);

            const endTime = performance.now() + jobContext.syncTimeSlice;
            for (; i<fragIds.length; i++) {

                // stop if we reached our memory limit.
                if (mc.costs >= jobContext.byteLimit) {
                    break;
                }

                // get fragId and world box
                var fragId = fragIds[i];
                fragList.getWorldBounds(fragId, fragBox);

                // add mesh to consolidation
                var geometry = fragList.getGeometry(fragId);
                var material = fragList.getMaterial(fragId);
                mc.addGeom(geometry, material, fragBox, fragId, fragIdToNodeIdx);

                // check if we need to yield because we are running out of time
                if (i % FRAGMENTS_PER_TIME_CHECK === 0 && performance.now() > endTime) {
                    ++i;
                    setTimeout(addMoreGeoms, 0);
                    return;
                }
            }

            resolve(mc.createConsolidationMap(fragIds, i, fragIdToNodeIdx));
        };

        addMoreGeoms();
    });
}

/**
 * Combines a sequence of fragments with shared geometry and material into an instanced mesh.
 * This instanced mesh is added to 'result'.
 *
 * For fragments that cannot be instanced, we add an individual mesh instead that shares
 * original geometry and material. This happens if:
 *
 *  a) The is just a single instance (range length 1)
 *  b) The instance has a matrix that cannot be decomposed into pos/scale/rotation.
 *
 *  @param {FragmentList}    fragList   - Fragmentlist of the model to consolidate
 *  @param {MaterialManager} materials  - Needed to create new materials for instanced shapes
 *  @param {Int32Array}      fragIds    - Array of fragment ids to consolidate
 *  @param {number}          rangeStart - defines a range within the fragIds array
 *  @param {number}          rangeEnd   - end of the range within the fragIds array
 *  @param {Consolidation}   result     - collects the resulting mesh.
 */
var applyInstancingToRange = (function (){

    var _tempMatrix = null;

    return function(model, materials, fragIds, rangeStart, rangeEnd, result) {

        var fragList = model.getFragmentList();

        // init temp matrix
        if (!_tempMatrix) { _tempMatrix = new THREE.Matrix4(); }

        var firstFrag = fragIds[rangeStart];

        // get geometry and material (must be the same for all frags in the range)
        var geom  = fragList.getGeometry(firstFrag);
        var mat   = fragList.getMaterial(firstFrag);

        // just a single instance? => add it directly
        var rangeLength = rangeEnd - rangeStart;
        var lastIndex = rangeEnd - 1;
        if (rangeLength <= MINIMAL_INSTANCES_FOR_INSTANCED_RENDERING) {
            for (let i=rangeStart; i<=lastIndex; i++)
                result.addSingleFragment(fragList, fragIds[i]);
            return;
        }

        // create instanced geometry from geom and all transforms
        var builder = new InstanceBufferBuilder(geom, rangeLength);
        for (let i=rangeStart; i<=lastIndex; i++) {

            var fragId = fragIds[i];

            // world matrix and dbId
            fragList.getOriginalWorldMatrix(fragId, _tempMatrix);
            var dbId = fragList.fragments.fragId2dbId[fragId];

            // try to process as instanced mesh
            var valid = builder.addInstance(_tempMatrix, dbId);

            // If adding this instance failed, its matrix did not allow to
            // be represented as pos/rotation/scale. In this case, add
            // the mesh individually.
            if (!valid) {
                // Swap last and current. This keeps all of the fragments
                // in the instanced buffer together.
                var tmp = fragIds[lastIndex];
                fragIds[lastIndex] = fragId;
                fragIds[i] = tmp;
                --i;
                --lastIndex;
            }
        }

        var instGeom = builder.finish();

        // instGeom might be null if all instances had matrices that could not be decomposed.
        // In this case, all frags have been skipped and will be added individually below
        if (instGeom) {

            // create instancing material
            var instMat  = materials.getMaterialVariant(mat, MATERIAL_VARIANT.INSTANCED, model);

            // add instanced mesh
            result.addContainerMesh(instGeom, instMat, fragIds, rangeStart, rangeLength);
            // Set start of fragment id range.
            result.meshes[result.meshes.length - 1].rangeStart = rangeStart;
        }

        // if we had to skip any fragment, add it separately. Note that this must be done after
        // adding the container, so that fragId2MeshIndex finally refers to the individual geometry.
        for (let i=lastIndex+1; i<rangeEnd; i++) {
            fragId = fragIds[i];
            result.addSingleFragment(fragList, fragId);
        }
    };
}());

/**
 * Combines fragments with shared geometries into instanced meshes. Note that creating instanced meshes
 * only makes sense for fragments that share geometry and material. All other fragments will also be
 * added to the result, but the meshes will share original geometry and material.
 *
 * Requirement: fragIds must already be sorted in a way that meshes with identical geometry and material form
 *              a contiguous range.
 *
 * @param {RenderModel}             model      - Model to be consolidated
 * @param {MaterialManager}         materials  - Needed to create new materials for instanced shapes
 * @param {Consolidation} consolidation        - Consolidation object to which the instancing is applied
 * @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 * @param {Promise<Consolidation>} result - collects all output meshes
 */
async function applyInstancing(model, materials, consolidation, jobContext) {

    var fragList = model.getFragmentList();

    // the first n=numConsolidated fragments in fragIds are consolidated already.
    // The remaining fragIds are now processed using hardware instancing.
    const fragIds = jobContext.consolidationMap.fragOrder;
    const startIndex = jobContext.consolidationMap.numConsolidated;

    if (startIndex >= fragIds.length) {
        // range empty
        // This may happen if we could consolidate all fragments per mesh merging already, so
        // that instancing is not needed anymore.
        return;
    }

    // track ranges of equal geometry and material
    var rangeStart = startIndex;
    var lastGeomId = -1;
    var lastMatId  = -1;
    let lastNodeIdx = -1;
    let indexCount = 0;
    let geom = undefined;
    const fragIdToNodeIdx = jobContext.consolidationMap.fragIdToNodeIdx;

    await new Promise((resolve) => {
        jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.INSTANCING);

        let i = startIndex;
        const applyInstancingToFrags = () => {
            const endTime = performance.now() + jobContext.syncTimeSlice;
            for (; i < fragIds.length; i++) {
                var fragId = fragIds[i];
                var geomId = fragList.getGeometryId(fragId);
                var matId  = fragList.getMaterialId(fragId);
                var nodeIdx = fragIdToNodeIdx ? fragIdToNodeIdx[fragId] : -1;

                // check if a new range starts here
                // If case of per tile consolidation, we allow pulling in more fragments from other nodes
                // to a certain degree to increase render batch sizes.
                if (geomId != lastGeomId || matId != lastMatId || 
                    (nodeIdx != lastNodeIdx && indexCount > MAX_INDICES_PER_RANGE)) {

                    // a new range starts at index i
                    // => process previous range [rangeStart, ..., i-1]
                    if (i!=startIndex) {
                        applyInstancingToRange(model, materials, fragIds, rangeStart, i, consolidation);
                    }

                    // start new range
                    rangeStart = i;
                    lastGeomId = geomId;
                    lastMatId = matId;
                    lastNodeIdx = nodeIdx;
                    indexCount = 0;
                    geom = fragList.getGeometry(fragId);
                }

                indexCount += geom.ib ? geom.ib.length : 0;

                if (i % FRAGMENTS_PER_TIME_CHECK === 0 && performance.now() > endTime) {
                    ++i;
                    setTimeout(applyInstancingToFrags, 0);
                    return;
                }
            }

            resolve();
        };

        applyInstancingToFrags();
    });

    // process final range
    applyInstancingToRange(model, materials, fragIds, rangeStart, fragIds.length, consolidation);
}

/**
 * Returns an array that enumerates all fragIds in a way that...
 *
 *  1. They are ordered by increasing memory costs that it takes to consolidate them.
 *  2. FragIds with equal geometry and material form a contiguous range.
 *  3. If available by bvh node index, so that fragments in the same node are grouped together.
 *
 *  @param {RenderModel} model                  - The model to consolidate
 *  @param {Int32Array}  [sortedFragIds]        - Optional: If available, the array will be reused to store the result 
 *  @param {Uint16Array} [fragIdToNodeIdx]      - Optional: If available, sorting will take into account the bvh node index
 *  @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 *  @returns {Promise<Int32Array>} ordered list of fragment ids, wrapped in a promise
 */
async function sortByConsolidationCosts(model, sortedFragIds, fragIdToNodeIdx, jobContext) {
    jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.SORTING);

    const fragList = model.getFragmentList();
    const geomList = model.getGeometryList();

    // a single missing geometry shouldn't make the whole consolidation fail.
    // therefore, we exclude any null-geometry fragemnts.
    var validFrags = 0;

    // create fragId array [0,1,2,...]
    var fragCount = fragList.getCount();
    var fragIds = sortedFragIds && sortedFragIds.length === fragCount ? sortedFragIds : new Int32Array(fragCount);
    const memCosts = new Uint32Array(fragCount);
    for (var i=0; i<fragCount; i++) {

        // exclude fragments without valid geometry
        if (!fragList.hasGeometry(i)) {
            continue;
        }

        fragIds[validFrags] = i;
        const geom = fragList.getGeometry(i);
        const geomId = fragList.getGeometryId(i);
        const instCount = geomList.getInstanceCount(geomId);

        memCosts[i] = instCount * geom.byteSize;
        validFrags++;
    }

    // resize array if we had to skip fragments
    if (validFrags < fragCount) {
        fragIds = new Int32Array(fragIds.buffer, fragIds.byteOffset, validFrags);
    }

    // We do the actual sorting in a worker to avoid blocking the main thread
    let returnResult;
    return new Promise((resolve) => {
        const jobId = workerJobId++;

        // Worker Callback
        returnResult = (result) => {
            // We might get calls for previous jobs, so we need to check if the result is for the current job
            if (result.data.jobId === jobId) {
                resolve(result.data.fragIds);
            }
        };
        jobContext.worker.addEventListener('message', returnResult);

        const context = {
            operation: "SORT_FRAGMENTS",
            fragIds,
            geomIds: fragList.geomids,
            memCosts,
            materialIds: fragList.materialids,
            fragIdToNodeIdx,
            jobId
        };        

        jobContext.worker.doOperation(context);
    }).finally(() => {
        jobContext.worker?.removeEventListener('message', returnResult);
    });
}

/**
 * For tile based consolidation a BVH is needed. So we compute a one suited for consolidation
 * @param {RenderModel} model                  - The model to consolidate
 * @param {Uint32Array} fragIdToNodeIdx        - maps fragment id to bvh node index
 * @param {Int32Array} sortedFragIds           - will be filled with sorted fragment ids by consolidation costs
 * @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 */
async function computeConsolidationBVH(model, fragIdToNodeIdx, sortedFragIds, jobContext) {

    const fl = model.getFragmentList();

    // We are copying the data from the fragment list into a format that can be passed to a worker
    // This is necessary because the fragment list is not available in the worker
    // This creates some temporary memory overhead, but if we don't have enough memory for this we shouldn't 
    // do consolidation anyway
    const fragments = {
        boxes: fl.fragments.boxes,
        polygonCounts: new Uint32Array(fl.fragments.length),
        flags: new Uint8Array(fl.fragments.length),
        materials: fl.materialids,
        length: fl.fragments.length,
        geomids: fl.geomids,
    };

    // Compute which fragments can be consolidated and which are transparent
    sortedFragIds = await sortByConsolidationCosts(model, sortedFragIds, fragIdToNodeIdx, jobContext);
    const materialIdMap = model.getFragmentList().materialIdMap;
    let consolidationCosts = 0;
    for (let i = 0; i < sortedFragIds.length; ++i) {
        const fragId = sortedFragIds[i];
        const geom = fl.getGeometry(fragId);
        fragments.polygonCounts[fragId] = geom ? geom.polyCount : 0;
        consolidationCosts += geom.byteSize;
        if (consolidationCosts <= jobContext.byteLimit)
            fragments.flags[fragId] = 1; // can be consolidated

        const materialDef = materialIdMap && materialIdMap[fragments.materials[fragId]];
        fragments.flags[fragId] |= materialDef && materialDef.transparent ? 2 : 0;
    }

    // offload the bvh computation to a worker
    let returnResult;
    const bvh = await new Promise((resolve) => {
        const jobId = workerJobId++;

        // Worker Callback
        returnResult = (result) => {
            // We might get calls for previous jobs, so we need to check if the result is for the current job
            if (result.data.jobId === jobId) {
                const bvh = result.data.bvh;
                resolve({ nodes: new NodeArray(bvh.nodes, bvh.useLeanNodes), primitives: bvh.primitives});
            }
        };
        jobContext.worker.addEventListener('message', returnResult);

        jobContext.worker.doOperation({
            operation: "COMPUTE_BVH",
            fragments,
            modelId: model.id,
            bvhOptions: jobContext.bvhOptions,
            jobId
        }, [fragments.polygonCounts.buffer, fragments.flags.buffer]);
    }).finally(() => {
        jobContext.worker?.removeEventListener('message', returnResult);
    });

    jobContext.consolidationBVH = bvh;
    jobContext.consolidationBVH.bvhOptions = jobContext.bvhOptions;

    jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.BVH);
    
    // Compute Fragment Id to BVH node mapping
    let computeFragIdToNodeIdx = (nodeIdx) => {
        const start = bvh.nodes.getPrimStart(nodeIdx);
        
        const end = start + bvh.nodes.getPrimCount(nodeIdx);
        for (let i = start; i < end; ++i) {
            fragIdToNodeIdx[bvh.primitives[i]] = nodeIdx;
        }
        
        const leftChild = bvh.nodes.getLeftChild(nodeIdx);
        if (leftChild != -1) {
            computeFragIdToNodeIdx(leftChild);
            computeFragIdToNodeIdx(leftChild + 1);
        }
    };

    computeFragIdToNodeIdx(0); // Opaque Nodes
    computeFragIdToNodeIdx(1); // Transparent Nodes
}

/**
 * Get the current consolidation map or create a new one if it does not exist yet or if parameters have changed
 * requirung a recomputation.
 * @param {RenderModel}             model       - The model to consolidate 
 * @param {ConsolidationJobContext} jobContext  - Context information for the consolidation job
 * @returns {Promise<ConsolidationMap>}
 */
async function getOrCreateConsolidationMap(model, jobContext) {
    if (!jobContext.consolidationMap || jobContext.forceConsolidationMapRecomputation) {
        const fragList = model.getFragmentList();
        let fragIdToNodeIdx = null; // maps fragment id to bvh node index (only needed for per-tile consolidation)
        let sortedFragIds = new Int32Array(fragList.getCount());

        if (jobContext.bvhOptions?.per_tile_consolidation) {
            fragIdToNodeIdx = new Uint32Array(fragList.getCount());
            await computeConsolidationBVH(model, fragIdToNodeIdx, sortedFragIds, jobContext);
        }

        // create consolidation map
        sortedFragIds = await sortByConsolidationCosts(model, sortedFragIds, fragIdToNodeIdx, jobContext);
        jobContext.consolidationMap = await createConsolidationMap(model.getFragmentList(), sortedFragIds, fragIdToNodeIdx, jobContext);
    }

    return jobContext.consolidationMap;
}

/**
 *  Creates a consolidated representation of a fragments. For each fragment f, there will be a mesh in the result that
 *  contains it - or shares its geometry if was not mergeable with any other fragment.
 *
 *   @param {RenderModel}     model               - The model to consolidate
 *   @param {MaterialManager} materials           - needed to create new material variants for consolidated/instanced meshes
 *   @param {ConsolidationJobContext} jobContext  - Context information for the consolidation job
 *   @returns {Promise<Consolidation>}            - Returns a promise that resolves to a Consolidation object
 */
export async function consolidateFragmentList(model, materials, jobContext) {
    const fragList = model.getFragmentList();

    // If not available yet, create ConsolidationMap that describes the mapping from src fragments
    // into consolidated meshes.
    const consMap = jobContext.consolidationMap = await getOrCreateConsolidationMap(model, jobContext);

    // Create Consolidation
    const consolidation = consMap.buildConsolidation(fragList, materials, model, jobContext.useDeferredConsolidation);

    // Apply instancing to all remaining fragments that were not consolidated yet
    await applyInstancing(model, materials, consolidation, jobContext);

    // Set modelId for all consolidate/instanced meshes (needed to distinguish multiple models via ID-buffer)
    const modelId = model.getModelId();
    for (let i = 0; i < consolidation.meshes.length; i++) {
        consolidation.meshes[i].modelId = modelId;
    }

    return consolidation;
}
