import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getEnvMapSampleChunk} from "../chunks/env_sample";
import tonemap from "../chunks/tonemap.wgsl";

export function getEnvMapShader(isGammaEncoded, tonemapMethod) {

return wgsl/*wgsl*/`
@group(0) @binding(0) var envMap: texture_cube<f32>;
@group(0) @binding(1) var envSampler: sampler;

struct EnvMapUniforms {
	uCamDir: vec4f,
	uCamUp: vec4f,
	uResolution: vec2f,
	uHalfFovTan: f32,
	envMapExposure: f32,
	exposureBias: f32,
	tonemapMethod: i32
}
@group(1) @binding(0) var<uniform> u : EnvMapUniforms;

${tonemap}
${getEnvMapSampleChunk(isGammaEncoded, !isGammaEncoded, tonemapMethod > 0)}

fn rayDir(vUv: vec2f) -> vec3f {
    var A = (u.uResolution.x/u.uResolution.y)*normalize(cross(u.uCamDir.xyz,u.uCamUp.xyz)) * (u.uHalfFovTan * 2.0);
    var B = normalize(u.uCamUp.xyz) * (u.uHalfFovTan * 2.0);
    var C = normalize(u.uCamDir.xyz);

    var ray = normalize( C + (2.0*vUv.x - 1.0)*A + (2.0*vUv.y - 1.0)*B );
    return ray;
}


@fragment
fn main	(
	@builtin(position) coord : vec4f,
	@location(0) @interpolate(linear) vUv: vec2f
) -> @location(0) vec4f {

	var rd = rayDir(vUv);

	var outColor = sampleIrradianceMap(rd, envMap, envSampler, u.envMapExposure).xyz;

#if ${tonemapMethod === 1}
	outColor = toneMapCanonOGS_WithGamma_WithColorPerserving(u.exposureBias * outColor.xyz);
#elif ${tonemapMethod === 2}
	outColor = toneMapCanonFilmic_WithGamma(u.exposureBias * outColor.xyz);
#endif

	return vec4f(outColor, 1.0);
}
`;
}
