import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getIBLDeclaration} from "../main/IBL";
import {getCameraUniformsDeclaration} from "../main/CameraUniforms";
import {getObjectUniformsDeclaration} from "../main/ObjectUniforms";
import {getLineUniformsDeclaration} from "./LineUniforms";
import hatch from "../chunks/hatch_pattern.wgsl";


export function getLineShaderSS(material) {

return wgsl/*wgsl*/`

${getIBLDeclaration(0)}
${getCameraUniformsDeclaration(1)}
${getObjectUniformsDeclaration(2)}
${getLineUniformsDeclaration(3)}
${hatch}

const hatchTintColor = vec3f(1, 1, 1);

// Common defines
const TAU = 6.28318530718;
const PI = 3.14159265358979;
const HALF_PI = 1.57079632679;
const PI_1_5 = 4.71238898038; // 1.5 * PI

// discard fragments if they belong to ghosted layers
@id(0) override ENABLE_ID_DISCARD: u32 = 1;

// Geometry type defines
const VBB_GT_TRIANGLE_INDEXED = 0;
const VBB_GT_LINE_SEGMENT     = 1;
const VBB_GT_ARC_CIRCULAR     = 2;
const VBB_GT_ARC_ELLIPTICAL   = 3;
const VBB_GT_TEX_QUAD         = 4;
const VBB_GT_ONE_TRIANGLE     = 5;
const VBB_GT_MSDF_TRIANGLE_INDEXED = 6;

// ID defines
const VBB_INSTANCED_FLAG   = 0;
const VBB_SEG_START_RIGHT  = 0;
const VBB_SEG_START_LEFT   = 1;
const VBB_SEG_END_RIGHT    = 2;
const VBB_SEG_END_LEFT     = 3;

// Defines scaling of line style patterns (e.g. for dashed and dotted lines)
// We define some fixed scaling here.
//
// Why 0.25?: The choice is currently sort-of arbitrary - just manually adjusted
//           to look reasonable when drawing into 2D overlays. If this is not sufficient, we could make it
//           adjustable per shader parameter.
//
// AutoCAD will scale patterns based on the LTSCALE setting, and currently this is ignored here.
const LTSCALE = 0.25;

//Attributes
struct VsInput {
	@builtin(instance_index) instance : u32,
	@location(0) fields1: vec2f,
	@location(1) fields2: vec4f,
	@location(2) color4b: vec4f,
	@location(3) dbId4b: vec4u,
	@location(4) flags4b: vec4u,
	@location(5) layerVp4b: vec4u
}

// Varyings
struct VsOutput {
	@builtin(position) position : vec4f,
	//TODO: most of these should be tagged @interpolate(flat)
	@location(0) @interpolate(flat) fsColor: vec4f,
	@location(1) fsOffsetDirection: vec2f,
	@location(2) fsMultipurpose: vec4f,
	//fsGeomType: f32,
	@location(3) @interpolate(flat) fsHalfWidth: f32,
	@location(4) fsVpTC: vec2f,
	@location(5) @interpolate(flat) fsGhosting: f32,
	@location(6) @interpolate(flat) dbId: vec4u,
	@location(7) vWorldPosition: vec3f, //for cutplanes
	@location(8) @interpolate(flat) instance: u32,
#if ${material.hasLineStyles}
	@location(9) vPixelsPerUnit: f32
#endif
}

// used internally by the VS
var<private> centralVertex: vec2f;
var<private> offsetPosition: vec2f;
var<private> offsetPosDepth: vec2f;

fn cos_sin(angle: f32) -> vec2f {
	return vec2f(cos(angle), sin(angle));
}

fn getVertexId(in: VsInput) -> u32 { return in.flags4b.x; }

fn isStartVertex(in: VsInput) -> bool { return (getVertexId(in) < VBB_SEG_END_RIGHT); }
fn isLeftVertex(in: VsInput) -> bool  { var id = getVertexId(in); return ((id == VBB_SEG_END_LEFT || id == VBB_SEG_START_LEFT)); }

struct SegmentData {
	angle: f32,
	distAlong: f32,
	distTotal: f32,
	lineWidthHalf: f32,
	lineType: u32
};

fn decodeSegmentData(in: VsInput) -> SegmentData {
	var seg: SegmentData;
	seg.angle         = in.fields2.x * TAU - PI;
	seg.distAlong     = in.fields2.y;
	seg.distTotal     = in.fields2.w;
	seg.lineWidthHalf = in.fields2.z;
	seg.lineType      = in.flags4b.z;

	return seg;
}

fn strokeLineSegment(in: VsInput, out: ptr<function, VsOutput>) {
	var seg = decodeSegmentData(in);

	//Compute output point based on start point plus segment length and direction
	//This is because the line segment's 4 vertices are all equal to the start
	//point to begin with. We move the vertex to the correct place in the quad
	//based on vertex flags indicating whether it's left/right/top/bottom vertex in the
	//quad.
	var isStartCapVertex: f32;
	if (isStartVertex(in)) {
		isStartCapVertex = -1.0;
	} else {
		isStartCapVertex = 1.0;
	}

	var isLeftSide: f32;
	if (isLeftVertex(in)) {
		isLeftSide = 1.0;
	} else {
		isLeftSide = -1.0;
	}

	var objectUniforms = getObjectUniforms(in.instance);

	var mvpMatrix = uniforms.projectionMatrix * uniforms.viewMatrix * objectUniforms.modelMatrix;

	//Screen space start position
	var startPosition = (mvpMatrix * vec4f(centralVertex.xy, 0.0, 1.0));

	var distanceAlong = seg.distAlong;
	var along = distanceAlong * cos_sin(seg.angle);

	var endPosition = (mvpMatrix * vec4f(centralVertex.xy + along, 0.0, 1.0));

	var screenStart = startPosition.xy * 0.5 * u.size / startPosition.w;
	var screenEnd = endPosition.xy * 0.5 * u.size / endPosition.w;

	if (isStartCapVertex < 0.0) {
		offsetPosition = screenStart;
		centralVertex = screenStart;
		offsetPosDepth = startPosition.zw;
	} else {
		offsetPosition = screenEnd;
		centralVertex = screenEnd;
		offsetPosDepth = endPosition.zw;
	}

	//Apply transverse line width offset to the start/endpoint
	var screenDelta = screenEnd - screenStart;
	var screenAngle = 0.0;
	if (distanceAlong != 0.0) {
		screenAngle = atan2(screenDelta.y, screenDelta.x);
	}
	var angleTransverse = screenAngle - isLeftSide * HALF_PI;
	var lwAdjustment = (*out).fsHalfWidth + u.aaRange;
	var transverseOffset = cos_sin(angleTransverse) * lwAdjustment;
	offsetPosition += transverseOffset;

	//Apply start/end-cap extension offsets if needed
	var moveOffset = isStartCapVertex * isLeftSide * vec2f(-transverseOffset.y, transverseOffset.x);
	offsetPosition += moveOffset;
	centralVertex += moveOffset;

	//Distance we care about beyond the actual line segment vertex.
	//For start vertex, this is negative and equal to half a line weight
	//For end vertex this is the segment length plus the half line weight adjustment.
	var distanceFromStart = max(0.0, isStartCapVertex) * length(screenDelta);

	(*out).fsMultipurpose = vec4f(
		(isStartCapVertex * lwAdjustment) + distanceFromStart, //distance after end point that we want to fill with cap/join
		length(screenDelta),
		seg.distTotal,
		f32(seg.lineType)
	);

	if (seg.lineWidthHalf < 0.0) {
		(*out).fsHalfWidth = -(*out).fsHalfWidth;
	}

	//Vector from logical line centerline to the pixel position of the thick
	//line being shaded. We use this distance for antialiasing and line caps clipping
	(*out).fsOffsetDirection = offsetPosition - centralVertex;

	//Back to clip space coords
	(*out).position = vec4(2.0 * offsetPosition / u.size * offsetPosDepth.y, offsetPosDepth.xy);
}



struct CommonAttribs {
	pos: vec2f,
	color: vec4f,
	layerTC: vec2f,
	vpTC: vec2f,
	lineWidthHalf: f32,
	geomType: u32,
	ghosting: u32
}

fn decodeCommonAttribs(in: VsInput) -> CommonAttribs {
	var attribs : CommonAttribs;
	attribs.pos           = in.fields1.xy;
	attribs.color         = in.color4b;
	attribs.geomType      = in.flags4b.y;
	attribs.layerTC       = vec2f(in.layerVp4b.xy) / 255.0;
	attribs.vpTC          = vec2f(in.layerVp4b.zw) / 255.0;
	attribs.lineWidthHalf = in.fields2.z;
	attribs.ghosting      = in.flags4b.w;
	return attribs;
}

fn strokeIndexedTriangle(in: VsInput, out: ptr<function, VsOutput>) {
	// nothing to go, since centralVertex = attribs.pos already happened in main()...
	(*out).fsHalfWidth = 0.0;
	(*out).fsMultipurpose = vec4f(0.0);
	var objectUniforms = getObjectUniforms(in.instance);
	(*out).position = uniforms.projectionMatrix * uniforms.viewMatrix * objectUniforms.modelMatrix * vec4(centralVertex.xy, 0.0, 1.0);
}

fn getColor(attribs: CommonAttribs) -> vec4f {
	//Check layer visibility
	//TODO:
	//if (isLayerOff(attribs)) { return vec4(0.0); }
	return attribs.color;
}

@vertex fn vsmain(in: VsInput) -> VsOutput {

	var out: VsOutput;

	var objectUniforms = getObjectUniforms(in.instance);
	var attribs = decodeCommonAttribs(in);

	out.instance = in.instance;
	out.fsColor = getColor(attribs);


	// LMV-1133: Add AutoCAD-like display functionality, swapping black and white line and fill elements.
	/*
	if (u.swap != 0.0 ) {
		// if black, go to white
		if ( fsColor.r == 0.0 && fsColor.g == 0.0 && fsColor.b == 0.0 ) {
			fsColor.rgb = vec3(1.0,1.0,1.0);
		// if white, go to black
		} else if ( fsColor.r == 1.0 && fsColor.g == 1.0 && fsColor.b == 1.0 ) {
			fsColor.rgb = vec3(0.0,0.0,0.0);
		}
	}
	*/

	centralVertex = attribs.pos;
	offsetPosition = attribs.pos;

	var lineWeight = attribs.lineWidthHalf;

	var ppu = u.pixelsPerUnit;
	// For perspective camera calculate pixelsPerUnit according to vertex distance from camera.
	// Previously this was done as a general value with distance taken from center of the model,
	// but this is more accurate
	if (u.tanHalfFov > 0.0) { // For ortho camera, tanHalfFov is set to 0 in the uniform

		// There's a bit of chicken and egg problem here. The offsetPosition is modified later
		// (e.g. see strokeLineSegment) but fsHalfWidth needs to be calculated beforehand for that,
		// and that in itself is based on the pixelsPerUnit. So if we wait until the end to calculate
		// ppu according to the final offsetPosition it will be too late anyway and would need to be recalculated.
		// This should be a good enough approximation, though.
		var cameraPos = uniforms.viewMatrixInverse[3].xyz;
		var worldPos = objectUniforms.modelMatrix * vec4f(offsetPosition.xy, 0.0, 1.0);
		var distanceToCamera = length(cameraPos - worldPos.xyz);
		ppu = u.size.y / (2.0 * distanceToCamera * u.tanHalfFov);
	}

#if ${material.hasLineStyles}
	out.vPixelsPerUnit = ppu; // Fragment shader only uses for linestyles (in the meantime...)
#endif

	if (lineWeight > 0.0) {
		//Do not go below a line width of one pixel
		//Since we store, half-widths, the comparison is to 0.5 instead of 1.0
		lineWeight = max(0.5, lineWeight * ppu);
	}
	else {
		//Negative line weight means device space (pixel) width.
		//Currently used for antialiasing of polygon outlines.
		lineWeight = max(0.5, abs(lineWeight));
	}

	out.fsHalfWidth = lineWeight;

	out.dbId = vec4u(in.dbId4b);

	out.fsVpTC     = attribs.vpTC;
	out.fsGhosting = f32(attribs.ghosting);

	if (attribs.geomType == VBB_GT_LINE_SEGMENT) {
		strokeLineSegment(in, &out);
	} else /*if (attribs.geomType == VBB_GT_TRIANGLE_INDEXED)*/ {
		strokeIndexedTriangle(in, &out);
	}

	//fsGeomType = attribs.geomType;

	// pass world-pos for cut planes
	var worldPosition = objectUniforms.modelMatrix * vec4f( offsetPosition.xy, 0.0, 1.0 );
	out.vWorldPosition = worldPosition.xyz;

	return out;
}


//Gaussian falloff function
fn curveGaussian(r : f32, width: f32) -> f32 {
	var amt = clamp(abs(r / (width * 1.0)), 0.0, 1.0);
	amt = max(amt - 0.0, 0.0);

	var exponent = amt * 2.0;

	return clamp(exp(-exponent*exponent), 0.0, 1.0);
}

#if ${material.hasLineStyles}
fn getLinePatternPixel(i: u32, j: u32) -> f32 {
	return f32(lineStyles[j * u.lineStyleWidth + i]) * 255.0;
}

fn getPatternLength(whichPattern: u32) -> f32 {
	var p1 = getLinePatternPixel(0, whichPattern);
	var p2 = getLinePatternPixel(1, whichPattern);
	return (p2 * 256.0 + p1);
}
#endif


fn fillLineSegment(in: VsOutput, out: ptr<function, PixelOutput>) {

	var radius = abs(in.fsHalfWidth);
	var parametricDistance = in.fsMultipurpose.x;
	var segmentLength      = in.fsMultipurpose.y;
	var totalDistance      = in.fsMultipurpose.z;

	//Apply any dot/dash linetype
#if ${material.hasLineStyles}
	var whichPattern       = u32(in.fsMultipurpose.w);

	if (whichPattern > 0) {
		const TEX_TO_UNIT = 1.0 / 96.0;

		var patternScale: f32;

		//If line width is negative it means device space line style (zoom invariant)
		//line width, which also implies the same about the line pattern -- check for this here.
		if (in.fsHalfWidth < 0.0) {
			patternScale = LTSCALE;
		} else {
			patternScale = LTSCALE * TEX_TO_UNIT * in.vPixelsPerUnit;
		}

		var patLen = patternScale * getPatternLength(whichPattern);
		var phase = (totalDistance + parametricDistance) % patLen;

		var onPixel = true;
		var radiusPixels = radius + u.aaRange;

		for (var i: u32 = 2; i < u.lineStyleWidth; i += 2) {

			var on = getLinePatternPixel(i, whichPattern);
			if (on == 1.0) {
				on = 0.0; //special handling for dots, map length 1 to 0
			}
			on *= patternScale;

			onPixel = true;
			phase -= on;
			if (phase < 0.0) {
				break;
			}
			else if (phase <= radiusPixels) {
				onPixel = false;
				break;
			}

			var off = getLinePatternPixel(i+1, whichPattern);
			if (off <= 1.0) {
				off = 0.0; //special handling for dots, map length 1 to 0
			}
			off *= patternScale;

			onPixel = false;
			phase -= off;
			if (phase < -radiusPixels) {
				discard;
			}
			if (phase <= 0.0) {
				break;
			}
		}

		//Modify the parametricDistance value used for round cap
		//rendering to reflect the current position along the dash,
		//so that dashes get caps also.
		if (!onPixel && (abs(phase) <= radiusPixels)) {
			segmentLength = 0.0;
			parametricDistance = phase;
		}
	}
#endif // HAS_LINESTYLES

	//Check for end cap or join region -- here we reduce
	///allowed distance from centerline in a circular way
	//to get a round cap/join
	var dist: f32;
	var offsetLength2 = dot(in.fsOffsetDirection, in.fsOffsetDirection);
	/*
	 if (parametricDistance < 0.0) {
	   var d = parametricDistance;
	   dist = sqrt(d * d + offsetLength2);
	 } else if (parametricDistance >= segmentLength) {
	   var d = parametricDistance - segmentLength;
	   dist = sqrt(d * d + offsetLength2);
	 } else {
	   dist = sqrt(offsetLength2);
	 }
	*/

	//Branchless version of the above ifs (because who doesn't like to do boolean logic with float ops?):
	var ltz = max(0.0, sign(-parametricDistance));
	var gtsl = max(0.0, sign(parametricDistance - segmentLength));
	var d = (ltz + gtsl) * (parametricDistance - gtsl * segmentLength);
	dist = sqrt(max(0.0, offsetLength2 + d*d));

	//pixel is too far out of the line center
	//so discard it
	var range =  dist - radius;

	//Here aaRange will always be 0.5 pixels.
	//The logic below blurs a thickness of 1 pixel, a distance of
	//(halfLineWidth - 0.5) to (halfLineWidth + 0.5) away from the centerline.

	//Fully outside, discard
	if (range > u.aaRange) {
		discard;
	}

	//The geometry covers this pixel -- do AA.
	(*out).color = in.fsColor;

	//Blur if we are in the blur range.
	//The exact parameters to the gaussian function (and inside it)
	//are based on personal preference observations
	if (range > -u.aaRange) {
		(*out).color = vec4f((*out).color.xyz, curveGaussian(range+u.aaRange, u.aaRange * 2.0));
	}
	//out.color.a *= curveGaussian(range, 1.0);
}


fn fillTriangle(in: VsOutput, out: ptr<function, PixelOutput>) {
	(*out).color = in.fsColor;
}


// Checks whether C is on the right/left of the AB vector
// Returns 0 on the line
//  -1 on right side
//   1 on left side
fn getSide(a: vec2f, b: vec2f, c: vec2f) -> f32 {
	return sign((b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x));
}


struct PixelOutput {
	@location(0) color: vec4f,
	@location(1) normal: vec4f,
	@location(2) viewDepth: vec4f,
	@location(3) dbId: vec4u,
	@location(4) modelId: vec4u,
	//@location(5) objectFlags: vec4u
}

@fragment fn psmain(in: VsOutput) -> PixelOutput {

	var out: PixelOutput;

	var objectUniforms = getObjectUniforms(in.instance);
	var materialUniforms = getMaterialUniforms(objectUniforms.materialIndex);

	//Is visibility off?
	//Note we check for all components to be zero,
	//because we still want the ability to mark the ID buffer
	//with IDs that do not render anything into the color buffer
	if (in.fsColor.a == 0.0 && in.fsColor.r == 0.0 && in.fsColor.g == 0.0 && in.fsColor.b == 0.0) {
		discard;
	}

	//Filled triangle, not a line, no need for extra math
	if (in.fsHalfWidth == 0.0) {
		fillTriangle(in, &out);

		// cap mesh hatch pattern
		if ((materialUniforms.renderFlags & 8) != 0) {
			out.color = calculateHatchPattern(materialUniforms.hatchParams, in.position.xy, out.color,
				hatchTintColor, materialUniforms.hatchTintIntensity);
		}
	}
	else {
		fillLineSegment(in, &out);
	}

	// The LineShader requires the ability to discard IDs independent of color. This is needed for ghosted 2D
	// objects, which are visible, but not selectable (allowing to select the object below).
	// To support this, the id_frag shader chunk supports the ENABLE_ID_DISCARD macro, which is always defined
	// by LineShader. If this macro is set, id_frag requires a variable writeId to control whether the id is
	// written (writeId=1.0) or not (writeId=0.0).
	var writeId = 1.0;

	out.color.a *= u.opacity;
	// For fully transparent pixels we can discard
	if (out.color.a == 0.0) {
		discard;
	}

	// Apply ghosting, i.e., make an object transparent and exclude it from ID buffer if...
	//  a) It is in the ghosting layer (see FragmentList.js)
	//  b) We are in 2D measure mode and it belongs to a different viewport than the first selected one
	if (in.fsGhosting != 0.0)
		//|| ((in.viewportId != 0.0) && (abs(in.fsVpTC.x * 255.0 + in.fsVpTC.y) >= 0.5 && abs(in.fsVpTC.x * 255.0 + in.fsVpTC.y - in.viewportId) >= 0.5)))
	{
		// apply ghosting
		writeId = 0.0;

		// When swapping black and white, must kick up faded inks a bit to give them more contrast
		// with the (likely to be black) background. By visual test, 0.21 looks good.
		//DT gl_FragColor.a *= ((u.swap == 1.0) ? 0.21 : 0.1);
		out.color.a *= 0.30;
	}

	//check doNotCut flag
	if (commonMaterialUniforms.doNotCutOverride + (materialUniforms.renderFlags & 2) == 0) {
		checkCutPlanes(in.vWorldPosition);
	}

	if (writeId == 1.0) {
		//Store object identity information
		out.dbId = in.dbId;

		//Currently we don't have enough room to bind a third ID render target (32 byte limit on render attachments),
		//so we stash half of the object flags in the modelId
		//output.objectFlags = intToVec(objectUniforms.objectFlags);

		out.modelId = intToVec(objectUniforms.modelId | (objectUniforms.objectFlags << 16));
	}

	return out;
}
`;
}
