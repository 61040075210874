import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getBlendSettingsDeclaration} from "./BlendSettings";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";


export function getBlendShader() {
	return wgsl/*wgsl*/`
	@group(0) @binding(0) var tColor: texture_2d<f32>;
	@group(0) @binding(1) var tOverlay: texture_2d<f32>;
	@group(0) @binding(2) var tID: texture_2d<u32>;
	@group(0) @binding(3) var tID2: texture_2d<u32>;
	//@group(0) @binding(4) var tID3: texture_2d<u32>;
	@group(0) @binding(4) var tAO: texture_2d<f32>;

	${getBlendSettingsDeclaration(1)}
	${tonemap}
	${hatch}

	fn toInt(v: vec4u) -> i32 {
		return i32(v.r | (v.g << 8) | (v.b << 16) | (v.a << 24));
	}

	// modify rgb by applying rollOver highlighting.
	// filterValue is an additonal multiplier for highlighting intensity. (always 1.0 if no spatial filter is used)
	fn applyHighlighting(inRgb: vec3f) -> vec3f {
		//rgb += highlightColor * filterValue * u.highlightIntensity * 0.20;
		var rgb = inRgb;
		rgb += vec3(1.0,1.0,1.0) * u.highlightIntensity * 0.20;
		return rgb;
	}

	//Color key based edge detection for selection set
	fn isSelectionColor(C : vec3f) -> bool {

		//Simple check that assumes the blue selection color
		if (C.b >= C.g && C.b >= C.r) {
			return true;
		}

		return false;
	/*
		var minS = min(u.selectionColor.r, min(u.selectionColor.g, u.selectionColor.b));
		var maxS = max(u.selectionColor.r, max(u.selectionColor.g, u.selectionColor.b));
		var satS = maxS - minS;
		var minC = min(C.r, min(C.g, C.b));
		var maxC = max(C.r, max(C.g, C.b));
		var satC = maxC - minC;
		// If one color has low saturation, they only match if both have low saturation.
		if (satC < .01 || satS < .01) {
			return false;
		}

		// Now compare the hue. We don't convert to a numeric hue, just
		// the vector representation.
		var S = (u.selectionColor.rgb - minS) / satS;
		var H = (C - minC) / satC;
		var D = H - S;
		var eps = .15;
		// We want to match the hue of selectionColor and C, but if the max component of C is
		// 1, then it may have been clamped which will change the hue. In this case the
		// middle colors should be higher than we expect, so we just make sure that
		// all of C's components are larger than selectionColor's.
		return (abs(D.r) + abs(D.g) + abs(D.b) < eps)
			|| (maxC >= (1.0 - eps) && D.r >= -eps && D.g >= -eps && D.b >= -eps);
			*/
	}

	fn sampleTargetSS(coord: vec2i, offset: vec2i) -> vec4f {
		return textureLoad(tOverlay, coord + offset, 0);
	}

	struct SumNeighbors {
		hasEdge: i32,
		selectionPixel: vec4f,
		maxAlpha: f32
	}
	var<private> sum: SumNeighbors;

	fn checkEdgeSelection(ssCoord: vec2i, offX: i32, offY: i32) {
		var c = sampleTargetSS( ssCoord, vec2i(offX, offY));
		sum.maxAlpha = max(sum.maxAlpha, c.a);
		if (c.a <= 0.0) {
			sum.hasEdge++;
		}
	}

	// search neighbors to detect change in pixel coverage
	// the current pixel is outline if is selection && has empty neighbors
	fn overlayEdgeDetect(ssCoord: vec2i) -> vec4f {

		//Note that the sampled color here has pre-multiplied alpha,
		//but we don't need to correct for that with the current isSelectionColor check
		var center = sampleTargetSS(ssCoord, vec2i(0));
		var paintOutline = false;

		if (center.a > 0.0 && isSelectionColor(center.rgb)) {
			checkEdgeSelection(ssCoord, -1,-1);
			checkEdgeSelection(ssCoord,  0,-1);
			checkEdgeSelection(ssCoord,  1,-1);

			checkEdgeSelection(ssCoord, -1, 0);
			checkEdgeSelection(ssCoord,  1, 0);

			checkEdgeSelection(ssCoord, -1, 1);
			checkEdgeSelection(ssCoord,  0, 1);
			checkEdgeSelection(ssCoord,  1, 1);

			checkEdgeSelection(ssCoord, -2,-2);
			checkEdgeSelection(ssCoord, -1,-2);
			checkEdgeSelection(ssCoord,  0,-2);
			checkEdgeSelection(ssCoord,  1,-2);
			checkEdgeSelection(ssCoord,  2,-2);

			checkEdgeSelection(ssCoord, -2,2);
			checkEdgeSelection(ssCoord, -1,2);
			checkEdgeSelection(ssCoord,  0,2);
			checkEdgeSelection(ssCoord,  1,2);
			checkEdgeSelection(ssCoord,  2,2);

			checkEdgeSelection(ssCoord, -2, -2);
			checkEdgeSelection(ssCoord, -2, -1);
			checkEdgeSelection(ssCoord, -2,  0);
			checkEdgeSelection(ssCoord, -2,  1);
			checkEdgeSelection(ssCoord, -2,  2);

			checkEdgeSelection(ssCoord, 2, -2);
			checkEdgeSelection(ssCoord, 2, -1);
			checkEdgeSelection(ssCoord, 2,  0);
			checkEdgeSelection(ssCoord, 2,  1);
			checkEdgeSelection(ssCoord, 2,  2);

			if (sum.hasEdge != 0) {               // if has empty neighbors
				paintOutline = true;
			} else {
				center.a = -center.a;        // special flag marking inside pixel
			}
		}

		// calculate outline color
		if (paintOutline) {
			var rgb = mix(sqrt(u.selectionColor.rgb), vec3f(1.0), 0.65);
			var a = 1.0; //make edge fully opaque so it's easier to see
			center = vec4f(rgb, a);
		}

		return center;
	}

	fn glowComp(c: i32) -> bool {
		if (u.glowCompFunc == 0) {
			return c == u.glowFlagInt;
		} else {
			return (c != -1) && ((c & u.glowFlagInt) != 0);
		}
	}

	var<private> hasEdgeGlow: i32;

	fn checkEdgeId(ssCoord: vec2i, offX: i32, offY: i32, W: i32) {
		//See corresponding comment in UberShader. We currently don't have enough room
		//to encode the object flags in a separate render target, so we put half of it
		//in the upper 2 bytes of the modelId render target (tID2). We need to find a solution
		//for the remaining two bytes, or an alternative solution that doesn't require the extra
		//target during the main pass (e.g. lookup into data texture by model/object ID which we already know)
		var c = toInt(textureLoad(tID2, ssCoord + vec2i(offX, offY), 0)) >> 16;
		if (glowComp(c)) {
			hasEdgeGlow += W;
		}
	}

	const glowColor = vec3f(1.0,1.0,0.0);

	// search 3x3 neighbors
	// the current pixel is outline if
	// (is selection && has empty neighbor) || (pixel is empty && has selection neighbor)
	fn glowEdgeDetect(ssCoord: vec2i) -> vec4f {

		hasEdgeGlow = 0;

		var paintOutline = false;

		checkEdgeId(ssCoord, -1,-1, 2);
		checkEdgeId(ssCoord, 0,-1, 4);
		checkEdgeId(ssCoord, 1,-1, 2);
		checkEdgeId(ssCoord,-1, 0, 4);
		checkEdgeId(ssCoord, 1, 0, 4);
		checkEdgeId(ssCoord,-1, 1, 2);
		checkEdgeId(ssCoord, 0, 1, 4);
		checkEdgeId(ssCoord, 1, 1, 2);

		checkEdgeId(ssCoord, 2, -1, 1);
		checkEdgeId(ssCoord, 2, 0, 1);
		checkEdgeId(ssCoord, 2, 1, 1);

		checkEdgeId(ssCoord, -2, -1, 1);
		checkEdgeId(ssCoord, -2,  0, 1);
		checkEdgeId(ssCoord, -2,  1, 1);

		checkEdgeId(ssCoord, -1, 2, 1);
		checkEdgeId(ssCoord, 0, 2, 1);
		checkEdgeId(ssCoord, 1, 2, 1);

		checkEdgeId(ssCoord, -1, -2, 1);
		checkEdgeId(ssCoord, 0, -2, 1);
		checkEdgeId(ssCoord, 1, -2, 1);

		if (hasEdgeGlow != 0) {              // if has object neighbors
			paintOutline = true;
		}

		// calculate outline color
		if (paintOutline) {
			return vec4f(glowColor.rgb, min(1.0, f32(hasEdgeGlow)/6.0));
		}

		return vec4f(0.0);
	}


	@fragment
	fn main(
		@builtin(position) coord : vec4f,
		@location(0) @interpolate(linear) vUv: vec2f
	) -> @location(0) vec4f {

		var pixelCoord = vec2i(floor(coord.xy));

		var outColor = textureLoad(tColor, pixelCoord, 0);

		if (u.useAO != 0) {
			var ao = textureLoad(tAO, pixelCoord, 0).r;
			outColor = vec4f(sqrt(outColor.rgb * outColor.rgb * min(1.0, ao+ u.aoBias)), outColor.a);
		}

		if (u.useOverlay != 0) {
			//composite in the overlays texture
			var overlay = overlayEdgeDetect(pixelCoord);

			// Negative alpha signals the inside overlay condition, as above.
			if (overlay.a < 0.0) {
				overlay.a = -overlay.a;

				if (overlay.a >= 0.99) {
					//Blend the overlay color with the luminance of the underlying
					//pixel so that we do not lose detail from any underlying texture map
					outColor = vec4f(vec3f(luminance_post(outColor.rgb)), 0.75);
				}
			}

			// For 3D, apply some hard-wired gamma correction.
			// Todo: When embedding 2D in 3D scenes, the gamma-correction will alter the 2D colors as well.
			//outColor = vec4f(outColor.rgb * (1.0 - overlay.a) + sqrt(overlay.rgb * overlay.a), outColor.a);
			//WEBGPU: do not gamma the overlay because it's currently using the UberShader that applies tone mapping + gamma in the pixel shader
			//Note we use pre-multiplied blend function here, because that's how our blend function is set up
			//to put pixels into the color targets
			outColor = vec4f(outColor.rgb * (1.0 - overlay.a) + overlay.rgb, outColor.a);

			//if (useOverlayAlpha != 0) {
				// In case texel (diffuse color) has 0 alpha, but overlay has something to render, make sure to update the alpha accordingly.
				outColor = vec4f(outColor.rgb, outColor.a + overlay.a * (1.0 - outColor.a));
			//}

			//test only
			//outColor = overlay;
		}

		//rollover highlighting
		if (u.objectId != 0) {
			var objectId = toInt(textureLoad(tID, pixelCoord, 0));
			var modelId = toInt(textureLoad(tID2, pixelCoord, 0)) & 0xffff;

			if (u.objectId == objectId && u.modelId == modelId) {
				outColor = vec4f(applyHighlighting(outColor.rgb), outColor.a);
			}
		}

		//glow effect
		if (u.glowFlagInt != 0) {

			var flagAtPixel = toInt(textureLoad(tID2, pixelCoord, 0)) >> 16;

			if (!glowComp(flagAtPixel)) {
				var outline = glowEdgeDetect(pixelCoord);
				outline.a *= u.highlightIntensity;
				outColor = vec4f(outColor.rgb * (1.0 - outline.a) + outline.rgb * outline.a, outColor.a);
			} else {
				var dist = calculateHatchPatternRaw(1.0, 10.0, coord.xy);
				var intensity : f32;
				if (dist <= 0.5) {
					intensity = 1.0;
				} else {
					intensity = 0.10;
				}
				//float intensity = 0.1;
				outColor = vec4f(mix(outColor.rgb, glowColor, intensity * u.highlightIntensity), outColor.a);
			}
		}


		//Encode luminance in A for the downstream FXAA pass
		//TODO: make this shader constant
		if (u.encodeLuma == 1) {
			outColor.a = luminance_post(outColor.rgb);
		}
		return outColor;
	}
	`;
}
