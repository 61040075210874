/** WGSL Preprocessor v1.0.0 **/
const preprocessorSymbols = /#([^\s]*)(\s*)/g;

// Template literal tag that handles simple preprocessor symbols for WGSL
// shaders. Supports #if/elif/else/endif statements.
function wgsl(strings, ...values) {
	const stateStack = [];
	let state = {
		string: '',
		elseIsValid: false,
		expression: true,
		levelProcessed: false,
		ignoreNested: false
	};

	for (let i = 0; i < strings.length; ++i) {
		const string = strings[i];
		const matchedSymbols = string.matchAll(preprocessorSymbols);

		let lastIndex = 0;
		let valueConsumed = false;

		for (const match of matchedSymbols) {
			if (state.expression && !state.levelProcessed) {
				state.string += string.substring(lastIndex, match.index);
			}

			switch (match[1]) {
				case 'if':
					if (match.index + match[0].length != string.length) {
						throw new Error('#if must be immediately followed by a template expression (ie: ${value})');
					}
					valueConsumed = true;
					stateStack.push(state);
					state = {
						string: '',
						elseIsValid: true,
						expression: !!values[i],
						levelProcessed: state.ignoreNested,
						ignoreNested: state.ignoreNested || !values[i]
					};
					break;
				case 'elif':
					if (match.index + match[0].length != string.length) {
						throw new Error('#elif must be immediately followed by a template expression (ie: ${value})');
					} else if (!state.elseIsValid) {
						throw new Error('#elif not preceeded by an #if or #elif');
					}
					valueConsumed = true;
					state.levelProcessed ||= state.expression;
					state.expression = !!values[i];
					state.ignoreNested = state.levelProcessed || !values[i];
					break;
				case 'else':
					if (!state.elseIsValid) {
						throw new Error('#else not preceeded by an #if or #elif');
					}
					state.levelProcessed ||= state.expression;
					state.ignoreNested = state.levelProcessed;
					state.expression = true;
					if (state.expression && !state.levelProcessed) {
						state.string += match[2];
					}
					state.elseIsValid = false;
					break;
				case 'endif': {
					if (!stateStack.length) {
						throw new Error('#endif not preceeded by an #if');
					}
					const branchState = state;
					state = stateStack.pop();
					state.string += branchState.string;
					state.string += match[2];
					break;
				}
				default:
					// Unknown preprocessor symbol. Emit it back into the output string unchanged.
					state.string += match[0];
					break;
			}

			lastIndex = match.index + match[0].length;
		}

		if (state.expression && !state.levelProcessed) {
			// If the string didn't end on one of the preprocessor symbols append the rest of it here.
			if (lastIndex != string.length) {
				state.string += string.substring(lastIndex, string.length);
			}

			// If the next value wasn't consumed by the preprocessor symbol, append it here.
			if (!valueConsumed && values.length > i) {
				state.string += values[i];
			}
		}
	}

	if (stateStack.length) {
		throw new Error('Mismatched #if/#endif count');
	}

	return state.string;
}

module.exports = {
	wgsl
};
