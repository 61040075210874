import {initCubeMap, refTexture, unrefTexture} from "../Texture";
import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {CreateCubeMapFromColors} from "../../render/DecodeEnvMap";

const NUM_HEATMAP_STOPS = 5;

export const getIBLDeclaration = function(bindGroupNumber) {

return wgsl/*wgsl*/`
	const NUM_STOPS = ${NUM_HEATMAP_STOPS};

	// Interleaving heatmap colors and stops for better alignment
	struct ColorsAndStops {
		color: vec3f,
		stop: f32
	};

	struct IBLSettings {
		envMapExposure: f32,
		exposureBias: f32,
		tonemapMethod: i32,
		numCutplanes: i32,
		heatmapAlpha: f32,
		pad1: u32,
		pad2: u32,
		pad3: u32,
		colorsAndStops: array<ColorsAndStops, NUM_STOPS>
	}
	@group(${bindGroupNumber}) @binding(0) var<uniform> ibl : IBLSettings;
	@group(${bindGroupNumber}) @binding(1) var reflMap: texture_cube<f32>;
	@group(${bindGroupNumber}) @binding(2) var reflSampler: sampler;
	@group(${bindGroupNumber}) @binding(3) var irrMap: texture_cube<f32>;
	@group(${bindGroupNumber}) @binding(4) var irrSampler: sampler;
	@group(${bindGroupNumber}) @binding(5) var<uniform> cutplanes: array<vec4f, 6>; //TODO: could use variable length array of needed
	@group(${bindGroupNumber}) @binding(6) var<storage> heatmapSensors: array<vec4f>;

	//TODO: Add lights here if we want to support discrete lights

	fn checkCutPlanes(worldPosition: vec3f) {
		for (var i=0; i<ibl.numCutplanes; i++) {
			// test if point is outside of cutting plane; if so, discard fragment
			if (dot(vec4f(worldPosition, 1.0), cutplanes[i]) > 0.0) {
				discard;
			}
		}
	}
`;
};


export function IBL(renderer) {

	let _renderer = renderer;
	let _device;

	let _defaultCubeMap = CreateCubeMapFromColors({x:1, y:1, z:1}, {x:1, y:1, z:1});
	let _reflMap = _defaultCubeMap, _irrMap = _defaultCubeMap;
	
	refTexture(_defaultCubeMap);
	refTexture(_reflMap);
	refTexture(_irrMap);

	let _exposureBias = 1.0;
	let _envMapExposure = 1.0;
	let _tonemapMethod = 0;

	let _uBufferCPU = new Float32Array(4 + NUM_HEATMAP_STOPS * 4 + 4);
	let _uBufferInt = new Int32Array(_uBufferCPU.buffer);
	let _uBuffer, _bgLayout, _bindGroup;

	let _cutBufferCPU = new Float32Array(24);
	let _numCutplanes = 0;
	let _cutBuffer;
	let _cutplanesDirty = false;

	const _colorsAndStops = new Float32Array(NUM_HEATMAP_STOPS * 4);
	let _heatmapAlpha;
	let _heatmapSensorBufferCPU;
	let _heatmapSensorBuffer;
	let _heatmapSensorsDirty = false;

	let _needsUpdate;
	let _uniformsDirty;


	this.init = function() {

		_device = _renderer.getDevice();

		_uBuffer = _device.createBuffer({
			size: _uBufferCPU.byteLength,
			usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
		});

		_cutBuffer = _device.createBuffer({
			size: _cutBufferCPU.byteLength,
			usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
		});

		// We're just allocating this as a placeholder here.
		// The buffer will be allocated with the required size when heatmaps are activated.
		_heatmapSensorBuffer = _device.createBuffer({
			size: 64,
			usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
		});

		_bgLayout = _device.createBindGroupLayout({
			entries: [
				{
					binding: 0,
					visibility: GPUShaderStage.FRAGMENT,
					buffer: {}
				},
				{
					binding: 1,
					visibility: GPUShaderStage.FRAGMENT,
					texture: {
						sampleType: 'float',
						viewDimension: "cube"
					}
				},
				{
					binding: 2,
					visibility: GPUShaderStage.FRAGMENT,
					sampler: {}
				},
				{
					binding: 3,
					visibility: GPUShaderStage.FRAGMENT,
					texture: {
						sampleType: 'float',
						viewDimension: "cube"
					}
				},
				{
					binding: 4,
					visibility: GPUShaderStage.FRAGMENT,
					sampler: {}
				},
				{
					binding: 5,
					visibility: GPUShaderStage.FRAGMENT,
					buffer: {}
				},
				{
					binding: 6,
					visibility: GPUShaderStage.FRAGMENT,
					buffer: {
						type: 'read-only-storage'
					}
				}
			]
		});
	};

	this.setReflectionMap = function(map) {
		if (map === _reflMap)
			return;
		// Setting to null would make the pipeline creation fail. Use _defaultCubeMap as a fallback to avoid this.
		// Note: This currently happens for 2D materials, for which IBL doesn't make sense anyway. So we should revise this.
		map ||= _defaultCubeMap;
		
		refTexture(map);
		unrefTexture(_reflMap);
		
		_reflMap = map;

		_needsUpdate = true;
	};

	this.setIrradianceMap = function(map) {
		// Tolerate null without breaking the pipeline creation. (see comment in setReflectionMap)
		map ||= _defaultCubeMap;

		refTexture(map);
		unrefTexture(_irrMap);
		
		_irrMap = map;

		_needsUpdate = true;
	};

	this.isComplete = function() {
		return _reflMap && _irrMap;
	};

	this.setEnvExposure = function(exposure) {

		const newValue = Math.pow(2.0, exposure);

		if (newValue !== _envMapExposure) {
			_envMapExposure = newValue;
			_uniformsDirty = true;
		}
	};

	this.setExposureBias = function(bias) {
		let newValue = Math.pow(2.0, bias);

		if (newValue !== _exposureBias) {
			_exposureBias = newValue;
			_uniformsDirty = true;
		}
	};

	this.setTonemapMethod = function(value) {
		if (_tonemapMethod !== value) {
			_tonemapMethod = value;
			_uniformsDirty = true;
		}
	};

	this.setEnvRotation = function(rotation) {
		//TODO:
	};

	//Sets global cutplanes. Some materials may also have "local" cutplanes
	//that apply only to those materials, and other material can ignore the global cutplanes
	//via doNotCut setting
	this.setCutPlanes = function(cutplanes) {
		if (_numCutplanes !== (cutplanes?.length || 0)) {
			_numCutplanes = cutplanes?.length || 0;
			_uniformsDirty = true;
		}

		if (!cutplanes) {
			return;
		}

		if (cutplanes.length > 6) {
			console.warn("too many cut planes");
		}

		let off = 0;
		for (let i=0; i<cutplanes.length; i++) {
			let plane = cutplanes[i];
			let v = plane.x;
			if (_cutBufferCPU[off] !== v) {
				_cutplanesDirty = true;
				_cutBufferCPU[off] = v;
			}
			off++;
			v = plane.y;
			if (_cutBufferCPU[off] !== v) {
				_cutplanesDirty = true;
				_cutBufferCPU[off] = v;
			}
			off++;
			v = plane.z;
			if (_cutBufferCPU[off] !== v) {
				_cutplanesDirty = true;
				_cutBufferCPU[off] = v;
			}
			off++;
			v = plane.w;
			if (_cutBufferCPU[off] !== v) {
				_cutplanesDirty = true;
				_cutBufferCPU[off] = v;
			}
			off++;
		}
	};

	this.setHeatmaps = function(colors, stops, alpha) {
		let offset = 0;
		for (let i = 0; i < NUM_HEATMAP_STOPS; ++i) {
			_colorsAndStops.set([
				colors[i * 3],
				colors[i * 3 + 1],
				colors[i * 3 + 2],
				stops[i]
			], offset);
			offset += 4;
		}

		_heatmapAlpha = alpha;

		_uniformsDirty = true;
	};

	this.setHeatmapSensors = function(sensors) {
		if (!_heatmapSensorBufferCPU || _heatmapSensorBufferCPU.length < sensors.length) {
			_heatmapSensorBufferCPU = new Float32Array(sensors);
		} else {
			_heatmapSensorBufferCPU.set(sensors);
		}

		_heatmapSensorsDirty = true;
	};

	function createBindGroup() {

		_bindGroup = _device.createBindGroup({
			layout: _bgLayout,
			entries: [
				{
					binding: 0,
					resource: {
						buffer: _uBuffer,
					},
				},
				{
					binding: 1,
					resource: _reflMap?.__gpuTextureCube.createView({
							dimension: "cube"
					})
				},
				{
					binding: 2,
					resource: _reflMap?.__gpuSampler
				},
				{
					binding: 3,
					resource: _irrMap?.__gpuTextureCube.createView({
							dimension: "cube"
					})
				},
				{
					binding: 4,
					resource: _irrMap?.__gpuSampler
				},
				{
					binding: 5,
					resource: {
						buffer: _cutBuffer
					}
				},
				{
					binding: 6,
					resource: {
						buffer: _heatmapSensorBuffer
					}
				}
			],
		});

		_renderer.invalidateRenderBundles();

	}

	this.getBindGroup = function() {
		return _bindGroup;
	};

	this.getLayout = function() {
		return _bgLayout;
	};

	this.update = function() {

		if (!_device) {
			return;
		}

		if (_uniformsDirty) {
			_uBufferCPU[0] = _envMapExposure;
			_uBufferCPU[1] = _exposureBias;
			_uBufferInt[2] = _tonemapMethod;
			_uBufferInt[3] = _numCutplanes;
			_uBufferCPU[4] = _heatmapAlpha;

			_uBufferCPU.set(_colorsAndStops, 8);


			_device.queue.writeBuffer(
				_uBuffer,
				0,
				_uBufferCPU.buffer,
				0,
				_uBufferCPU.byteLength
			);

			_uniformsDirty = false;
		}

		if (_cutplanesDirty) {
			_device.queue.writeBuffer(
				_cutBuffer,
				0,
				_cutBufferCPU.buffer,
				0,
				_cutBufferCPU.byteLength
			);

			_cutplanesDirty = false;
		}

		if (_heatmapSensorsDirty) {
			if (!_heatmapSensorBuffer || _heatmapSensorBuffer.size < _heatmapSensorBufferCPU.byteLength) {
				if (_heatmapSensorBuffer) {
					_heatmapSensorBuffer.destroy();
				}

				_heatmapSensorBuffer = _device.createBuffer({
					size: _heatmapSensorBufferCPU.byteLength,
					usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
				});

				_needsUpdate = true;
			}

			_device.queue.writeBuffer(
				_heatmapSensorBuffer,
				0,
				_heatmapSensorBufferCPU.buffer,
				0,
				_heatmapSensorBufferCPU.byteLength
			);

			_heatmapSensorsDirty = false;
		}

		if (_needsUpdate || _reflMap?.needsUpdate || _irrMap?.needsUpdate) {

			initCubeMap(_device, _reflMap);
			initCubeMap(_device, _irrMap);
			createBindGroup();

			_needsUpdate = false;
		}

	};


}
