import {wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";

import pack_depth from "../chunks/pack_depth.wgsl";

const USE_MIPMAP = true;

export function getSaoShader() {
return wgsl/*wgsl*/`

${pack_depth}

// Total number of direct samples to take at each pixel
const NUM_SAMPLES = 17;

// If using depth mip levels, the log of the maximum pixel offset before we need to switch to a lower
// miplevel to maintain reasonable spatial locality in the cache
// If this number is too small (< 3), too many taps will land in the same pixel, and we'll get bad variance that manifests as flashing.
// If it is too high (> 5), we'll get bad performance because we're not using the MIP levels effectively
const LOG_MAX_OFFSET: i32 = 3;

// This must be less than or equal to the MAX_MIP_LEVEL defined in SSAO.cpp
const MAX_MIP_LEVEL: i32 = 5;

// This is the number of turns around the circle that the spiral pattern makes.  This should be prime to prevent
// taps from lining up.  This particular choice (5) was tuned for NUM_SAMPLES == 17
// Here is the table. The one's digit is the column, the ten's is the row to look at.
// +0   1   2   3   4   5   6   7   8   9
//  1,  1,  1,  2,  3,  2,  5,  2,  3,  2,  // 00
//  3,  3,  5,  5,  3,  4,  7,  5,  5,  7,  // 10
//  9,  8,  5,  5,  7,  7,  7,  8,  5,  8,  // 20
// 11, 12,  7, 10, 13,  8, 11,  8,  7, 14,  // 30
// 11, 11, 13, 12, 13, 19, 17, 13, 11, 18,  // 40
// 19, 11, 11, 14, 17, 21, 15, 16, 17, 18,  // 50
// 13, 17, 11, 17, 19, 18, 25, 18, 19, 19,  // 60
// 29, 21, 19, 27, 31, 29, 21, 18, 17, 29,  // 70
// 31, 31, 23, 18, 25, 26, 25, 23, 19, 34,  // 80
// 19, 27, 21, 25, 39, 29, 17, 21, 27, 29}; // 90

const NUM_SPIRAL_TURNS = 5;

const MIN_RADIUS = 3.0; // pixels

const TAU = 6.28318530718;

//////////////////////////////////////////////////

//
// Uniforms and functions to reconstruct camera-space and world-space positions.
// in post-processing passes from the (linear) depth texture.
//
struct SAOUniforms {
	// vec4 used to reconstruct camera-space positions from screen-space + zValue (from tDepth).
	// It is computed from canvas resolution and projection matrix used during scene rendering by:
	// (see RenderContext.beginScene)
	//
	//  vec4(-2.0f / (width*P[0][0]),
	//       -2.0f / (height*P[1][1]),
	//      ( 1.0f - P[0][2]) / P[0][0],
	//      ( 1.0f + P[1][2]) / P[1][1])
	//
	//  where P is the projection matrix that maps camera space points
	//  to [-1, 1] x [-1, 1].
	projInfo: vec4f,

	/* The height in pixels of a 1m object if viewed from 1m away.
	  You can compute it from your projection matrix.  The actual value is just
	  a scale factor on radius; you can simply hardcode this to a constant (~500)
	  and make your radius value unitless (...but resolution dependent.)  */
	projScale: f32,

	cameraNear: f32,
	cameraFar: f32,
	lumInfluence: f32,// how much luminance affects occlusion

	/* World-space AO radius in scene units (r).  e.g., 1.0m */
	radius: f32,
	/* Bias to avoid AO in smooth corners, e.g., 0.01m */
	bias: f32,
	intensity: f32,
	isOrtho: i32 // 1 if ortho-camera is used, 0 for perspective
}

// Negative, "linear" depth values in world-space units
@group(0) @binding(0) var tNormals: texture_2d<f32>;
@group(0) @binding(1) var tDepth: texture_2d<f32>;
@group(0) @binding(2) var<uniform> u: SAOUniforms;

#if ${USE_MIPMAP}
	@group(0) @binding(3) var tDepth_mip: texture_2d<f32>;
#endif


/* intensity / radius^6 */
//uniform float intensityDivR6;
//float intensityDivR6 = intensity / pow(radius, 6.0);

/* Returns a unit vector and a screen-space radius for the tap on a unit disk (the caller should scale by the actual disk radius) */
fn tapLocation(sampleNumber: i32, spinAngle: f32) -> vec3f {
	// Radius relative to ssR
	var alpha = (f32(sampleNumber) + 0.5) * (1.0 / f32(NUM_SAMPLES));
	var angle = alpha * (f32(NUM_SPIRAL_TURNS) * TAU) + spinAngle;

	return vec3f(cos(angle), sin(angle), alpha);
}


/* Used for packing Z into the GB channels */
fn CSZToKey(z: f32) -> f32 {
	// convert from z in camera space to 0-1 space:
	// z is a negative value, near and far are positive
	// (-z-cameraNear) / (cameraFar-cameraNear)
	return clamp( (z + u.cameraNear) / (u.cameraNear - u.cameraFar), 0.0, 1.0);
}


/* Used for packing Z into the GB channels */
fn packKey(key: f32) -> vec2f {
	var p: vec2f;

	// Round to the nearest 1/256.0
	var temp = floor(key * 255.0);

	// Integer part
	p.x = temp * (1.0 / 255.0);

	// Fractional part
	p.y = key * 255.0 - temp;

	return p;
}

//Used to unpack depth value when input depth texture is RGBA8
fn unpackDepthNearFar( packedDepth: vec3f ) -> f32 {
	var depth = unpackDepth10(packedDepth);
	if (depth == 0.0) {
		//pixel is sky box
		return -u.cameraFar * 1.0e10;
	}
	return -(depth * (u.cameraFar - u.cameraNear) + u.cameraNear);
}

/*
   Clipping plane constants for use by reconstructZ

   clipInfo = (z_f == -inf()) ? Vector3(z_n, -1.0f, 1.0f) : Vector3(z_n * z_f,  z_n - z_f,  z_f);
 */
//"uniform vec3      clipInfo;",
//"vec3 clipInfo = vec3(cameraNear * cameraFar, cameraNear - cameraFar, cameraFar);",

//"float reconstructCSZ(float d) {",
//    "return clipInfo[0] / (clipInfo[1] * d + clipInfo[2]);",
//"}",

/* Reconstructs screen-space unit normal from screen-space position */
//vec3 reconstructCSFaceNormal(vec3 C) {
//    return normalize(cross(dFdy(C), dFdx(C)));
//}

//vec3 reconstructNonUnitCSFaceNormal(vec3 C) {
//    return cross(dFdy(C), dFdx(C));
//}


// Reconstruct camera-space P.xyz from screen-space fragCoords = (x, y) in
//  pixels and camera-space z < 0.  Assumes that the upper-left pixel center
//  is at (0.5, 0.5) [but that need not be the location at which the sample tap
//  was placed!]
//
//  Costs 3 MADD.  Error is on the order of 10^3 at the far plane, partly due to z precision.
fn reconstructCSPosition(fragCoords: vec2f, z: f32) -> vec3f {
	var ndc = (fragCoords * u.projInfo.xy + u.projInfo.zw);

	if (u.isOrtho != 0) {
		return vec3f(ndc * -1.0, z);
	} else {
		return vec3f(ndc * z, z);
	}
}

/* Read the camera-space position of the point at screen-space pixel ssP */
fn getPosition(ssP: vec2<i32>, depth: f32) -> vec3f {
	// Offset to pixel center
	var P = reconstructCSPosition(vec2f(ssP) + vec2f(0.5), depth);
	return P;
}

/* Read the camera-space position of the point at screen-space pixel ssP + unitOffset * ssR.  Assumes length(unitOffset) == 1 */
fn getOffsetPosition(ssC: vec2i, unitOffset: vec2f, ssR: f32) -> vec3f {

	var ssP = vec2i(ssR * unitOffset) + ssC;

	var z: f32;

	// We need to divide by 2^mipLevel to read the appropriately scaled coordinate from a MIP-map.
	// Manually clamp to the texture size because texelFetch bypasses the texture unit
	//"ivec2 mipP = clamp(ssP >> mipLevel, ivec2(0), textureSize(CS_Z_buffer, mipLevel) - ivec2(1));",
	//"ivec2 mipP = ssP;",
	//"P.z = texelFetch(tDepth, mipP, 0).z;",

#if ${USE_MIPMAP}
	var mipLevel: u32 = u32(clamp(i32(floor(log2(ssR))) - LOG_MAX_OFFSET, 0, MAX_MIP_LEVEL));

	if (mipLevel == 0) {
		z = unpackDepthNearFar(textureLoad(tDepth, ssP, 0).rgb);
	}
	else {
		mipLevel -= 1;
		var mipP: vec2i = clamp(ssP >> vec2u(mipLevel + 1), vec2i(0), vec2i(textureDimensions(tDepth_mip, mipLevel)) - vec2i(1));
		z = unpackDepthNearFar(textureLoad(tDepth_mip, mipP, mipLevel).rgb);
	}
#else
	z = unpackDepthNearFar(textureLoad(tDepth, ssP, 0).rgb);
#endif


	// Offset to pixel center
	return reconstructCSPosition(vec2f(ssP) + vec2f(0.5), z);
}

/** Compute the occlusion due to sample with index \a i about the pixel at \a ssC that corresponds
  to camera-space point \a C with unit normal \a n_C, using maximum screen-space sampling radius \a ssDiskRadius

  Note that units of H() in the HPG12 paper are meters, not
  unitless.  The whole falloff/sampling function is therefore
  unitless.  In this implementation, we factor out (9 / radius).

  Four versions of the falloff function are implemented below
 */
fn sampleAO(ssC: vec2i, C: vec3f, n_C: vec3f, ssDiskRadius: f32, tapIndex: i32, randomPatternRotationAngle: f32) -> f32 {
	// Offset on the unit disk, spun for this pixel
	var unitOffset = tapLocation(tapIndex, randomPatternRotationAngle);
	var ssR = unitOffset.z;

	// Ensure that the taps are at least 1 pixel away
	ssR = max(0.75, ssR * ssDiskRadius);

	// The occluding point in camera space
	var Q = getOffsetPosition(ssC, unitOffset.xy, ssR);

	// aoValueFromPositionsAndNormal() in original code
	var v = Q - C;

	var vv = dot(v, v);
	var vn = dot(v, n_C);

	const epsilon = 0.001;    // was 0.01, but in G3D code it's 0.001

	// Without the angular adjustment term, surfaces seen head-on have less AO
	var angAdjust = mix(1.0, max(0.0, 1.5 * n_C.z), 0.35);

	// fallOffFunction()
	// comment out this line for lower quality function:
//#define HIGH_QUALITY
	// A: From the HPG12 paper
	// Note large epsilon to avoid overdarkening within cracks
	//"return angAdjust * float(vv < radius * radius) * max((vn - bias) / (epsilon + vv), 0.0) * (radius * radius) * 0.6;",

	// B: Smoother transition to zero (lowers contrast, smoothing out corners). [Recommended]
//#ifdef HIGH_QUALITY

	// Higher quality version:
	// Epsilon inside the sqrt for rsqrt operation
	var f = max(1.0 - vv / (u.radius * u.radius), 0.0); return angAdjust * f * max((vn - u.bias) / sqrt(epsilon + vv), 0.0);
//# else
	// Avoid the square root from above.
	//  Assumes the desired result is intensity/radius^6 in main()
	//var f = max(u.radius * u.radius - vv, 0.0); return angAdjust * f * f * f * max((vn - u.bias) / (epsilon + vv), 0.0);
//# endif

	// C: Medium contrast (which looks better at high radii), no division.  Note that the
	// contribution still falls off with radius^2, but we've adjusted the rate in a way that is
	// more computationally efficient and happens to be aesthetically pleasing.
	//"return angAdjust * 4.0 * max(1.0 - vv / (radius * radius), 0.0) * max(vn - bias, 0.0);",

	// D: Low contrast, no division operation
	//"return angAdjust * 2.0 * float(vv < radius * radius) * max(vn - bias, 0.0);",
}


// user variables


// random angle in radians between 0 and 2 PI
fn getRandomAngle(pos: vec2f) -> f32 {
	// from http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/
	var dt = dot(pos ,vec2f(12.9898,78.233));
	return TAU * fract(sin(dt % 3.14) * 43758.5453);
}

@fragment fn psmain(
	@builtin(position) position: vec4f,
	@location(0) @interpolate(linear) vUv: vec2f
) -> @location(0) vec4f {

	// Pixel being shaded
	var ssC = vec2i(floor(position.xy));

	//get the normal and depth from our normal+depth texture
	var nrmz = textureLoad(tNormals, ssC, 0);
	var depth = unpackDepth10(textureLoad(tDepth, ssC, 0).rgb);

	//TODO: figure out how to hook this up with a depth test
	// Unneccessary with depth test.
	if (depth == 0.0) {
		// We're on the skybox
		return vec4f(1.0, packKey(1.0), 0.0);
	}

	depth = -(depth * (u.cameraFar - u.cameraNear) + u.cameraNear);

	var output: vec4f;

	// Camera space point being shaded
	var C = getPosition(ssC, depth);

	var outputGB = packKey(CSZToKey(C.z));
	output.g = outputGB.x;
	output.b = outputGB.y;

	// Choose the screen-space sample radius
	// proportional to the projected area of the sphere.
	// If orthographic, use 1.0 for the divisor, else use the world-space point's Z value.
	var ssDiskRadius = u.projScale * u.radius;
	if (u.isOrtho == 0) {
		ssDiskRadius /= -C.z;
	}

	var A: f32;
	if (ssDiskRadius <= MIN_RADIUS) {
		// There is no way to compute AO at this radius
		A = 1.0;
	} else {

		var sum = 0.0;

		// Hash function used in the HPG12 AlchemyAO paper
		//var randomPatternRotationAngle = modXXX(f32((((3 * ssC.x) ^ (ssC.y + ssC.x * ssC.y))) * 10), TAU);
		var randomPatternRotationAngle = getRandomAngle(vUv);

		// Reconstruct normals from positions. These will lead to 1-pixel black lines
		// at depth discontinuities, however the blur will wipe those out so they are not visible
		// in the final image.
		//vec3 n_C = reconstructCSFaceNormal(C);
		var n_C = unpackNormal10(nrmz.xyz);

		for (var i = 0; i < NUM_SAMPLES; i++) {
			sum += sampleAO(ssC, C, n_C, ssDiskRadius, i, randomPatternRotationAngle);
		}

		//var intensityDivR6 = u.intensity / pow(u.radius, 6.0);
		// high quality:
	//#ifdef HIGH_QUALITY
		A = pow(max(0.0, 1.0 - sqrt(sum * (3.0 / f32(NUM_SAMPLES)))), u.intensity);

	//# else
		// lower quality:
		//A = max(0.0, 1.0 - sum * intensityDivR6 * (5.0 / float(NUM_SAMPLES)));
		// Use the following line only with the lower quality formula.
		// Anti-tone map to reduce contrast and drag dark region farther
		// (x^0.2 + 1.2 * x^4)/2.2
		//A = (pow(A, 0.2) + 1.2 * A*A*A*A) / 2.2;
	//# endif

		// This code has been removed by the creator of the algorithm, see:
		// http://g3d.cs.williams.edu/websvn/filedetails.php?repname=g3d&path=%2FG3D10%2Fdata-files%2Fshader%2FAmbientOcclusion%2FAmbientOcclusion_AO.pix
		// In practice it seems to make no difference visually, but matters for
		// time spent in the benchmark! We see drops on 80 spheres metal and red of 20%. Weird.
		// So, we'll leave it in.
		// Bilateral box-filter over a quad for free, respecting depth edges
		// (the difference that this makes is subtle)
		//"if (abs(dFdx(C.z)) < 0.02) {",
		//    "A -= dFdx(A) * ((ssC.x & 1) - 0.5);",
		//"}",
		//"if (abs(dFdy(C.z)) < 0.02) {",
		//    "A -= dFdy(A) * ((ssC.y & 1) - 0.5);",
		//"}",
		/*
		if (abs(dFdx(C.z)) < 0.02) {
			A -= dFdx(A) * (mod(float(ssC.x), 2.0) - 0.5);
		}
		if (abs(dFdy(C.z)) < 0.02) {
			A -= dFdy(A) * (mod(float(ssC.y), 2.0) - 0.5);
		}
		*/

		// Fade in as the radius reaches 2 pixels
		A = mix(1.0, A, clamp(ssDiskRadius - MIN_RADIUS,0.0,1.0));
	}

	output.r = A;
	output.a = 1.0;

	// to show depths instead of SAO, uncomment:
	//output.r = CSZToKey(C.z);
	// to show normal component (pick one, x or y):
	//output.r = nrmz.y;

	return output;

}
`;}
