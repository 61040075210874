
const { isNodeJS } = require('../../compat');
var getResourceUrl = require('../../globals').getResourceUrl;

if (isNodeJS()) {

(function() {
    //Node.js case -- the web worker is a "fake" worker
    //running on the main thread.
    var MainWorker = require('../workers/MainWorker-node').MainWorker;

    function createWorker() {
        return new MainWorker();
    }

    function initWorkerScript() { }

    module.exports = {
        createWorker: createWorker,
        initWorkerScript: initWorkerScript
    };

})();

} else {

(function() {

var avp = Autodesk.Viewing.Private;

//Those are globals -- set by the build system.
var LMV_WORKER_URL = avp.LMV_WORKER_URL || "src/file-loaders/workers/MainWorker-web.js";


// A cache of entire worker script as data URL.
var WORKER_DATA_URL = null;

function initWorkerScript() {
    fetch(getResourceUrl(LMV_WORKER_URL));
}

// Create a web worker.
// @param {string} name - Arbitrary name of the worker for debugging, shows up e.g. in chrome profiler.
function createWorker(name, needForwardProtocolHandler) {

    // idea from https://github.com/webpack/webpack/discussions/14648#discussion-3666804
    // We cannot construct the worker directly from the URL because CORS.
    // In particular, when consuming LMV from a CDN, the worker script is not in the origin of the page.
    if (!WORKER_DATA_URL) {
        // getResourceUrl might return a relative path (iff options.lmvResourceRoot is set to a relative URL, which ACC does).
        // importScripts does not work with relative paths in workers created with a blob: uri. So we construct a new absolute URL here.
        // We use document.baseURI because that is what fetch/XmlHttpRequest/Worker() would use if you give them a relative URL.
        // If you pass in an absolute URL; the second URL() parameter is ignored.
        const scriptUrl = new URL(getResourceUrl(LMV_WORKER_URL), document.baseURI).href;
        WORKER_DATA_URL = URL.createObjectURL(
            new Blob([`importScripts("${scriptUrl}");`], {
                type: 'application/javascript'
            })
        );
    }
    let w = new Worker(WORKER_DATA_URL, { name });

    w.doOperation = w.postMessage;

    if(needForwardProtocolHandler ===  true) {
        avp.ViewingService.forwardProtocolHandlerToWorker(w);
    }

    return w;
}

module.exports = {
    createWorker: createWorker,
    initWorkerScript: initWorkerScript
};

})();
}
