import { Prefs3D } from "./PreferenceNames";
import { Preferences } from "./Preferences";
import { Profile } from "./Profile";

/**
 * @callback FFGlobalInitFunction
 * @param {*} value - The current value of the feature flag
 * @param {Preferences} prefs - Shared global preferences
 * @private
 */

/**
 * Central place to manage feature flags.
 * @private
 */
export class FeatureFlags {
    static #flags = new Map();

    /**
     * Adds a new feature flag.
     * 
     * @param {string} name
     * @param {*} defaultValue - Default value of the feature flag. Type can be anything, but typically a boolean.
     * @param {string} description
     * @param {FFGlobalInitFunction} globalInitFun - Function to call during Autodesk.Viewing.Initializer execution
     */
    static add(name, defaultValue, description, globalInitFun) {
        this.#flags.set(name, {
            value: defaultValue,
            description,
            globalInitFun,
        });
    }

    /**
     * Sets the value of a given feature flag.
     * 
     * @param {string} name - Feature flag name
     * @param {*} value - New feature flag value
     * @returns {*} The new value or undefined if the flag does not exist
     */
    static set(name, value) {
        const ff = this.#flags.get(name);
        if (!ff) {
            return;
        }
        
        return ff.value = value;
    }

    /**
     * Gets the value of a given feature flag.
     * 
     * @param {string} name - Feature flag name
     * @returns The feature flag value or undefined if the flag does not exist
     */
    static get(name) {
        return this.#flags.get(name)?.value;
    }

    /**
     * Initializes all feature flags in the global context independent of a viewer instance.
     */
    static async initializeGlobals() {
        const defaultProfile = new Profile(Autodesk.Viewing.ProfileSettings.Default);
        const prefs = new Preferences();
        defaultProfile.apply(prefs);

        const initPromises = [];
        this.#flags.forEach(({ value, globalInitFun }) => {
            if (globalInitFun) {
                initPromises.push(globalInitFun(value, prefs));
            }
        });

        return Promise.all(initPromises);
    }

    /**
     * Prints all feature flags to the console
     */
    static print() {
        console.log('Feature Flags:');
        this.#flags.forEach(({ value, description }, name) => {
            console.log(`${name} (${description}):`, value);
        });
    }
};

// ADD YOUR FEATURE FLAGS HERE

// The setting should be visible by default when the beta starts (invisible till then), and products have to activate the FF to opt-out of showing the beta setting.
FeatureFlags.add('HIDE_LARGE_MODEL_EXPERIENCE', true, 'Hides the setting to enable the Large Model Experience Beta', (value, prefs) => { 
    if (!value && prefs.get(Prefs3D.LARGE_MODEL_EXPERIENCE)) {
        Autodesk.Viewing.Private.useHLOD();
    }
});

